import {reactive, UnwrapRef} from "vue";
import {req} from "@/lib/utils";

export interface IFont {
    font_id: string
    url: string
    name: string
    preview_url: string
}

export const loadFonts = (): void => {
    req({
        url: 'cms/theme/font/list', success: rs => {
            fonts.length = 0
            fonts.push(...rs)
        }
    })
}
export const fonts: UnwrapRef<IFont[]> = reactive([])