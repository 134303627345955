export interface IDraw {
    type: string
    x: number
    y: number
    is_public: boolean
    option: IDrawTextOption | IDrawUrlImageOption | IDrawAppletQrOption | IDrawWxPosterOption | IDrawThroughTrainOption | IDrawGroupContentGroupOption
}

export class Draw implements IDraw {
    is_public = false;
    option: IDrawTextOption | IDrawUrlImageOption | IDrawAppletQrOption | IDrawWxPosterOption | IDrawThroughTrainOption | IDrawGroupContentGroupOption;
    type: string;
    x = 0;
    y = 0;

    constructor(type: string, option: IDrawTextOption | IDrawUrlImageOption | IDrawAppletQrOption | IDrawWxPosterOption | IDrawThroughTrainOption | IDrawGroupContentGroupOption) {
        this.option = option
        this.type = type
    }
}

export interface IDrawTextOption {
    title?: string
    text: string
    variable?: string
    length_max: number
    length_min: number
    font_url: string
    font_size: number
    color: string
    letter_spacing: number
    stroke_width: number
    stroke_color: string
    align: number
}

export interface IDrawUrlImageOption {
    image_url: string
    title?: string
    sql?: string
    width: number
    height: number
    radius: number[]
}

export interface IDrawAppletQrOption {
    width: number
    radius: number[]
}

export interface IDrawWxPosterOption {
    version: 1 | 2 | 3
}

export interface IDrawThroughTrainOption {
    version: 1 | 2 | 3 | 4 | 5 | 6
}

export interface IDrawGroupContentGroupOption {
    color: string
}